import React from 'react';
import * as Yup from 'yup';
import {Email, FirstName, LastName, Permission} from 'src/form-inputs';
import {NPI} from 'src/hook-form-inputs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {
  Credential,
  Instance,
  Payer,
  PayerCredential,
  Taggable,
  User,
} from 'src/models';
import {useSelector} from 'react-redux';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {mergeMap, of} from 'rxjs';
import StepForm from 'src/hook-form/step-form';
import PersonalInformationForm, {
  fields,
} from 'src/modules/users/components/personal-information-form';
import ReviewPage from 'src/modules/users/components/review-page';
import PermissionsForm from 'src/modules/users/components/permissions-form';
import RosterCredentialsForm from 'src/modules/users/components/roster-credentials-form';
import _ from 'lodash';
import {BASE_AUTH_URL} from 'react-native-dotenv';

const UserFormScreen = ({
  route,
  role,
  instance,
  navigation,
  user,
  payers,
  credential,
  payerCredentials,
  tags,
}: any) => {
  const {id = null}: any = route?.params || {};
  const database = useDatabase();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const validationSchema = Yup.object({
    firstName: FirstName.validation(),
    lastName: LastName.validation(),
    email: Email.validation(),
    roleId: Permission.validation(),
    npi: NPI.validation(),
  });

  const mappedPayers = _.keyBy(payerCredentials, 'payerId');

  const navigateBack = (
    alert: string | undefined,
    error: string | undefined,
  ) => {
    if (id && alert) {
      navigation.navigate('UserProfile', {
        screen: 'Summary',
        practitionerId: id,
        alert: alert,
        error: error,
      });
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Users');
    }
  };

  const onSubmit = async (values: any) => {
    let alert;
    let error;
    if (
      (await database
        .get(User.table)
        .query(
          Q.and(
            Q.where('email', values.email.toLowerCase()),
            Q.where('id', Q.notEq(id)),
            Q.where('_partition', selectedGroup),
          ),
        )
        .fetchCount()) > 0
    ) {
      return {
        email: {message: 'Email is already taken.'},
      };
    } else {
      if (id) {
        if (user?.email.toLowerCase() !== values?.email.toLowerCase()) {
          try {
            const response = await fetch(`${BASE_AUTH_URL}/change-username`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Transfer-Encoding': 'chunked',
              },
              body: JSON.stringify({
                newEmail: values.email,
                email: user.email,
              }),
            });
            if (response?.status === 200) {
              alert =
                'A verification email has been sent to the updated email address. Please check your inbox to validate the changes.';
            } else {
              error = 'Unable to complete email change request.';
            }
          } catch (err) {
            console.log('error', err);
          }
        }
        await user.updateEntity({
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          birthDate: values.birthDate,
          roleId: values.roleId,
          position: values.position,
          mobilePhone: values.mobilePhone,
          address: values.address,
          credentials: values.credentials,
          modifier: values.modifier,
          licenseNumber: values.licenseNumber,
        });
        const deleteTags = _.differenceWith(
          tags,
          values.tags,
          (value1, value2) => {
            return value1.id === value2;
          },
        );
        const differenceTags: string[] = _.differenceWith(
          values.tags,
          tags,
          (value1, value2) => {
            return value1 === value2.id;
          },
        );

        for (const tag of deleteTags) {
          const profileTags = await database
            .get(Taggable.table)
            .query(Q.where('tag_id', Q.eq(tag.id)))
            .fetch();
          for (const profileTag of profileTags) {
            await profileTag.delete();
          }
        }
        for (const tag of differenceTags) {
          database.write(async () => {
            await database?.get(Taggable.table).create(entity => {
              entity.partition = selectedGroup;
              entity.entityId = user.id;
              entity.entity = 'staff';
              entity.tagId = tag;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          });
        }

        if (credential) {
          await credential.updateEntity({
            tin: values.tin,
            npi: values.npi,
            ssn: values.ssn,
            taxonomy: values.taxonomy,
          });
        }
        if (instance.billing) {
          for (const payer of values.payers) {
            if (payer._id) {
              const payerCredential = await database
                .get(PayerCredential.table)
                .find(payer._id);
              payerCredential.updateEntity({
                legacyId: payer.legacyId,
              });
            } else {
              await database?.write(async () => {
                return database?.get(PayerCredential.table).create(entity => {
                  entity.partition = selectedGroup;
                  entity.credentialId = credential.id;
                  entity.legacyId = payer.legacyId;
                  entity.payerId = payer.id;
                  entity.createdBy = userId;
                  entity.updatedBy = userId;
                });
              });
            }
          }
        }
      } else {
        await database?.write(async () => {
          const createdUser = await database?.get(User.table).create(entity => {
            entity.partition = selectedGroup;
            entity.identifier = '';
            entity.firstName = values.firstName;
            entity.lastName = values.lastName;
            entity.email = values.email.toLowerCase();
            entity.gender = values.gender;
            entity.birthDate = values.birthDate;
            entity.roleId = values.roleId;
            entity.position = values.position;
            entity.mobilePhone = values.mobilePhone;
            entity.address = values.address;
            entity.credentials = values.credentials;
            entity.modifier = values.modifier;
            entity.licenseNumber = values.licenseNumber;
            entity.state = 'active';
            entity.createdBy = userId;
            entity.updatedBy = userId;
          });
          for (const tag of values.tags) {
            await database?.get(Taggable.table).create(entity => {
              entity.partition = selectedGroup;
              entity.entityId = createdUser.id;
              entity.entity = 'staff';
              entity.tagId = tag;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          const createdCredential = await database
            ?.get(Credential.table)
            .create(entity => {
              entity.partition = selectedGroup;
              entity.userId = createdUser.id;
              entity.tin = values.tin;
              entity.npi = values.npi;
              entity.ssn = values.ssn;
              entity.taxonomy = values.taxonomy;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          if (instance.billing) {
            for (const payer of values.payers) {
              await database?.get(PayerCredential.table).create(entity => {
                entity.partition = selectedGroup;
                entity.credentialId = createdCredential.id;
                entity.legacyId = payer.legacyId;
                entity.payerId = payer.id;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            }
          }

          return createdUser;
        });
      }
    }
    navigateBack(alert, error);
  };

  const steps = [
    {
      step: 'Personal Information',
      fields: fields,
      form: PersonalInformationForm,
      extras: {alert: alert},
      validated: false,
      touched: false,
    },
  ];
  if (role.userEdit) {
    steps.push({
      step: 'Permissions',
      fields: ['roleId'],
      form: PermissionsForm,
      extras: {alert},
      validated: false,
      touched: false,
    });
  }
  return (
    <StepForm
      onSubmit={onSubmit}
      defaultSteps={[
        ...steps,
        {
          step: 'Roster Credentials',
          fields: ['npi'],
          form: RosterCredentialsForm,
          extras: {
            billing: instance.billing,
          },
          validated: false,
          touched: false,
        },
        {
          step: 'Review',
          fields: [],
          form: ReviewPage,
          extras: {
            billing: instance.billing,
          },
          validated: false,
          touched: false,
        },
      ]}
      defaultValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        gender: user.gender,
        birthDate: user.birthDate,
        roleId: user.roleId,
        position: user.position,
        mobilePhone: user.mobilePhone,
        address: user.address,
        credentials: user.credentials,
        modifier: user.modifier,
        licenseNumber: user.licenseNumber,
        tin: credential.tin,
        npi: credential.npi,
        ssn: credential.ssn,
        taxonomy: credential.taxonomy,
        tags: tags.length ? tags.map((tag: any) => tag?.id) : [],
        payers: payers.map((payer: any) => {
          return {
            _id: mappedPayers[payer.id]?.id,
            id: payer.id,
            legacyId: mappedPayers[payer.id]?.legacyId || '',
          };
        }),
      }}
      validationSchema={validationSchema}
      navigateBack={navigateBack}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({authentication, route, database}: any) => ({
    profile: authentication.userId
      ? database.get(User.table).findAndObserve(authentication.userId)
      : of(),
    user: route?.params?.id
      ? database.get(User.table).findAndObserve(route?.params?.id)
      : of({
          firstName: '',
          lastName: '',
          email: '',
          gender: '',
          birthDate: new Date(),
          roleId: '',
          position: '',
          mobilePhone: '',
          address: {
            fullAddress: '',
          },
          credentials: [],
          modifier: '',
        }),
    payers: database
      .get(Payer.table)
      .query(Q.where('staff_ids', true), Q.where('deleted_at', Q.eq(null))),
    instance: database
      ?.get(Instance.table)
      .query(Q.where('_partition', authentication.selectedGroup))
      .observe()
      .pipe(mergeMap(x => x)),
  })),
  withObservables([], ({profile, user}: any) => {
    return {
      role: profile.role,
      credential: user.id
        ? user.credential
        : of({
            tin: '',
            npi: '',
            ssn: '',
            taxonomy: [],
          }),
    };
  }),
  withObservables([], ({credential, user}: any) => {
    return {
      payerCredentials: user.id ? credential.activePayerCredentials : of([]),
      tags: user.id ? user.activeTags : of([]),
    };
  }),
)(UserFormScreen);
